import React from 'react'

import { siteMetadata } from '../../gatsby-config'
import Layout from 'components/Layout'
import SEO from 'components/SEO'
import { injectIntl, FormattedMessage, FormattedHTMLMessage } from "gatsby-plugin-intl"

import Image1 from 'components/ServicesImages/img1'
import Image2 from 'components/ServicesImages/img2'
import Image3 from 'components/ServicesImages/img3'
import Image4 from 'components/ServicesImages/img4'
import Image5 from 'components/ServicesImages/img5'
import Image6 from 'components/ServicesImages/img6'

const ServicesPage = ({ intl }) => {

  const isThai = () => {
    return (intl.locale === "th")
  }

  return (
  <Layout>

    <SEO
          site={siteMetadata}
          title="Our Blog"
          description="We provide consultation and coaching services for students in Thailand and other countries who are interested in studying abroad. We specialize in those pursuing higher education, including but not limited to programs offered by colleges and universities. We are also well versed in advising those seeking double degrees, and encourage motivated students to consider them as an effective way to gain valuable experience in a short time-frame."
           />

  <section id="thumbnail">
    <div className="center-cropped">
        <img src={require('../images/services.jpeg')} alt="plan.jpeg" />
        <div className={isThai? 'centered th-iphone-x' : 'centered'}>
      <p id="thumbnailText"><em><FormattedMessage id="s_let_us_support" /></em></p>
    </div>
    </div>
    
  </section>

  <section id="ourServices" className="centeredText">
  <div className="container m-r-auto m-l-auto">
                <h1><FormattedMessage id="s_our_services" /></h1>
                <p><FormattedHTMLMessage id="s_our_services_p" /> </p>
  </div>
  </section>    

  <section id="stepsSection">   
    <div className="container">
    <div className="centeredText">
      <h2 className="centeredText text-align-center"><FormattedMessage id="s_4_steps" /></h2>
    </div>

      <div className="row">

            <div className="col-lg-4 col-12 mb-5">
                <Image1 alt="plan.jpeg" />
            </div>
            <div id="step_1" className="col-lg-8 col-12">
                <h3><FormattedMessage id="s_step_1" /></h3>
                <p><FormattedMessage id="s_step_1_p" />&nbsp;</p>
            </div>
            
            <div className="col-lg-4 col-12 mb-5">
            <Image2 alt="plan.jpeg" />
            </div>
            <div id="step_2" className="col-lg-8">
              <h3><FormattedMessage id="s_step_2" /> &nbsp;</h3>
              <p><FormattedMessage id="s_step_2_p" /> </p>
            </div>

            <div className="col-lg-4 col-12 mb-5">
            <Image3 alt="plan.jpeg" />
            </div>
            <div id="step_3" className="col-lg-8 col-12">
            <h3><FormattedMessage id="s_step_3" /> </h3>
                <p><FormattedMessage id="s_step_3_p" /> </p>
            </div>
            
            <div className="col-lg-4 col-12 mb-5">
            <Image4 alt="plan.jpeg" />
            </div>
            <div id="step_4" className="col-lg-8 col-12">
            <h3><FormattedMessage id="s_step_4" /> </h3>
                    <p><FormattedMessage id="s_step_4_p1" /> </p>
                    <p><strong><FormattedMessage id="s_step_4_p2_s" /></strong></p>
                    <p><FormattedMessage id="s_step_4_p3" />&nbsp;</p>
                    <p><strong><FormattedMessage id="s_step_4_p4_s" /></strong></p>
                    <p><FormattedMessage id="s_step_4_p5" />&nbsp;</p>
                    <p><strong><FormattedMessage id="s_step_4_p6_s" /> </strong></p>
                    <p><FormattedMessage id="s_step_4_p7" /> &nbsp;</p>
            </div>
            <div className="col-lg-4 col-12 mb-5">
            <Image5 alt="plan.jpeg" />
            </div>
            <div id="step_5" className="col-lg-8 col-12">
              <h3><FormattedMessage id="s_step_5" /> </h3>
                <p><FormattedMessage id="s_step_5_p" /> </p>
            </div>

            <div className="col-lg-4 col-12 mb-5">
            <Image6 alt="plan.jpeg" />
            </div>

            <div id="step_6" className="col-lg-8 col-12">
              <h3><FormattedMessage id="s_step_6" /> </h3>
              <p><FormattedMessage id="s_step_6_p" /> </p>
            
              { !(isThai(intl)) && <div> 
              <p><strong><FormattedMessage id="s_step_6_p1_s" /></strong></p>
              <p><FormattedMessage id="s_step_6_p1" />&nbsp;</p>
              </div>}

            </div>
          </div>
          </div>
          </section>
</Layout>
  )
}
export default injectIntl(ServicesPage)